"use client";
import React from "react";
import { Area, AreaChart, CartesianGrid, XAxis } from "recharts";
import { ChartContainer, ChartTooltip, ChartTooltipContent, } from "@/components/ui/chart";
var chartConfig = {
    filtered: {
        label: "Filtered",
        color: "rgb(37, 99, 235)",
        index: 0,
    },
    viewed: {
        label: "Viewed",
        color: "rgb(96, 168, 251)",
        index: 1,
    },
    applied: {
        label: "Applied",
        color: "rgb(91, 33, 182)",
        index: 2,
    },
};
export function JobsAreaChart(props) {
    var _a;
    var chartData = ((_a = props.stats) === null || _a === void 0 ? void 0 : _a.jobsByDate)
        ? Object.entries(props.stats.jobsByDate).map(function (_a) {
            var date = _a[0], item = _a[1];
            return ({
                month: item.label,
                filtered: item.filtered,
                viewed: item.viewed,
                applied: item.applied,
            });
        })
        : [];
    var lineType = "monotone";
    return (<>
            <ChartContainer config={chartConfig} className="w-full h-[250px]">
                <AreaChart width={500} height={250} accessibilityLayer data={chartData}>
                    <CartesianGrid vertical={false}/>
                    <XAxis dataKey="month" tickLine={false} axisLine={false} tickMargin={0}/>
                    <ChartTooltip cursor={true} content={<ChartTooltipContent indicator="dot"/>}/>
                    <defs>
                        <linearGradient id="fillApplied" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="var(--color-applied)" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="var(--color-applied)" stopOpacity={0.1}/>
                        </linearGradient>
                        <linearGradient id="fillViewed" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="var(--color-viewed)" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="var(--color-viewed)" stopOpacity={0.1}/>
                        </linearGradient>
                        <linearGradient id="fillFiltered" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="var(--color-filtered)" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="var(--color-filtered)" stopOpacity={0.1}/>
                        </linearGradient>
                    </defs>
                    <Area dataKey="applied" type={lineType} fill="url(#fillApplied)" fillOpacity={0.4} stroke="var(--color-applied)" stackId="a"/>
                    <Area dataKey="viewed" type={lineType} fill="url(#fillViewed)" fillOpacity={0.4} stroke="var(--color-viewed)" stackId="a"/>
                    <Area dataKey="filtered" type={lineType} fill="url(#fillFiltered)" fillOpacity={0.4} stroke="var(--color-filtered)" stackId="a"/>
                </AreaChart>
            </ChartContainer>
        </>);
}
