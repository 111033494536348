import { Controller } from "@hotwired/stimulus";
import { getComponent } from "@symfony/ux-live-component";
import {createRoot} from "react-dom/client";
import { ProposalsAreaChart } from "@/components/proposalsAreaChart";
import React from "react";

export default class extends Controller {
    async initialize() {
        this.component = await getComponent(this.element);

        this.initializeChart();
        this.initializeDropdowns();
        this.initializeTooltips();

        this.component.on('loading.state:started', (component) => {
            this.addLoadingState();
        });

        this.component.on('render:started', (component) => {

        });

        this.component.on('render:finished', (component) => {
            this.initializeChart();
            this.initializeDropdowns();
            this.initializeTooltips();
            this.removeLoadingState();
        });
    }

    addLoadingState() {
        this.element.classList.add('loading');
    }

    removeLoadingState() {
        this.element.classList.remove('loading');
    }

    initializeDropdowns() {
        const dropdowns = this.element.querySelectorAll(".init-hs-dropdown-toggle");
        dropdowns.forEach((dropdown) => {
            dropdown.classList.remove("init-hs-dropdown-toggle");
            dropdown.classList.add("hs-dropdown-toggle");
            HSDropdown.autoInit(dropdown);
        });
    }

    initializeTooltips() {
        const tooltips = this.element.querySelectorAll(".init-hs-tooltip");
        tooltips.forEach((tooltip) => {
            tooltip.classList.remove("init-hs-tooltip");
            tooltip.classList.add("hs-tooltip");
            HSTooltip.autoInit(tooltip);
        });
    }

    initializeChart() {
        const rootElement = this.element.querySelector("#proposals-area-chart");
        if (rootElement) {
            const props = JSON.parse(rootElement.getAttribute("data-props"));

            if (this.root) {
                this.root.unmount();
            }

            this.root = createRoot(rootElement);
            this.root.render(<ProposalsAreaChart {...props} />);
        }
    }
}