import { Controller } from "@hotwired/stimulus";
import { getComponent } from "@symfony/ux-live-component";
import {createRoot} from "react-dom/client";
import { JobsAreaChart } from "@/components/jobsAreaChart";
import React from "react";

export default class extends Controller {
    async initialize() {
        this.component = await getComponent(this.element);

        this.initializeChart();

        this.component.on('loading.state:started', (component) => {
            this.addLoadingState();
        });

        this.component.on('render:started', (component) => {

        });

        this.component.on('render:finished', (component) => {
            this.initializeChart();
            this.removeLoadingState();
        });
    }

    addLoadingState() {
        this.element.classList.add('loading');
    }

    removeLoadingState() {
        this.element.classList.remove('loading');
    }

    initializeChart() {
        const rootElement = this.element.querySelector("#jobs-area-chart");
        if (rootElement) {
            const props = JSON.parse(rootElement.getAttribute("data-props"));

            if (this.root) {
                this.root.unmount();
            }

            this.root = createRoot(rootElement);
            this.root.render(<JobsAreaChart {...props} />);
        }
    }
}