import { Controller } from "@hotwired/stimulus";
import { getComponent } from "@symfony/ux-live-component";

export default class extends Controller {
    async initialize() {
        this.component = await getComponent(this.element);

        this.initializeProposalDetailsToggle();
        this.initializeTooltips();

        this.component.on('loading.state:started', (component) => {
            this.addLoadingState();
        });

        this.component.on('render:started', (component) => {
            this.closeProposalDetails();
        });

        this.component.on('render:finished', (component) => {
            this.initializeTooltips();
            this.removeLoadingState();
        });
    }

    addLoadingState() {
        this.element.classList.add('loading');
    }

    removeLoadingState() {
        this.element.classList.remove('loading');
    }

    closeProposalDetails() {
        let trs = this.element.querySelectorAll('.js-proposal-details-toggle');
        trs.forEach((tr) => {
            tr.classList.remove('open');
            tr.nextElementSibling.classList.remove('open');
        });
    }

    initializeTooltips() {
        const tooltips = this.element.querySelectorAll(".init-hs-tooltip");
        tooltips.forEach((tooltip) => {
            tooltip.classList.remove("init-hs-tooltip");
            tooltip.classList.add("hs-tooltip");
            HSTooltip.autoInit(tooltip);
        });
    }

    initializeProposalDetailsToggle() {
        this.element.addEventListener('click', function (event) {
            const target = event.target.closest('.js-proposal-details-toggle');
            if (!target || !this.contains(target)) return;

            target.classList.toggle('open');

            let nextTr = target.nextElementSibling;
            if (nextTr && nextTr.tagName.toLowerCase() === 'tr') {
                nextTr.classList.toggle('open');
            }
        });
    }
}